<template>
  <v-simple-table>
    <template v-slot:default>
      <tbody>
        <tr v-for="(item, index) in tableData" :key="index">
          <td class="font-weight-medium">{{ item.title }}</td>
          <td v-if="item.isHtml" v-html="item.value"></td>
          <td v-else>{{ item.value }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'SettingTable',

  props: {
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
</script>
